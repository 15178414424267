import flag from './flag.png';
import './ShipsInfo.sass';

export default function ShipsInfo() {
	return (
		<div className='ships-info'>
			<img src={flag} alt='flag' />
			<p>All orders ship from the USA within 1 business day.</p>
		</div>
	);
}
