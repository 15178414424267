import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import StepHeader from '../StepHeader/StepHeader';
import ShipsInfo from '../ShipsInfo/ShipsInfo';
import Checkbox from '../Form/Checkbox/Checkbox';
import { states } from './states';
import { validateStep } from '../../utils/orderFormUtils';
import { errorMessages } from './errorMessages';
import './ShippingInfo.sass';
import { buildQueryParams } from '../../utils/helpers';

export default function ShippingInfo(props) {
	const {
		onPressBack,
		onFieldUpdate,
		orderData,
		isOneLineCheckout,
		onSmsCheckboxChange,
		onSubmitStep,
		formErrors,
	} = props;

	// eslint-disable-next-line no-restricted-globals
	const queryParams = buildQueryParams(location);

	function handleSubmit() {
		const errors = validateStep(orderData, errorMessages);
		onSubmitStep(orderData, errors, 3);
	}

	function renderCountrySelect() {
		return (
			<select
				value={orderData.shippingCountry}
				placeholder='Country'
				name='shippingCountry'
				onChange={onFieldUpdate}
				className={formErrors.shippingCountry && 'error'}
			>
				<option value='US'>United States</option>
				<option value='CA'>Canada</option>
			</select>
		);
	}

	function renderStateSelect() {
		// Remove restricted states from states if SolVolt offer
		if (queryParams.offerid === '66461c98e81a190011fb3092') {
			const restrictedStates = ['CT', 'HI', 'IL', 'IN', 'MA', 'MI', 'NC', 'RI', 'VI', 'WA', 'DC'];

			for (const state in states['US']) {
				if (restrictedStates.includes(state)) {
					delete states['US'][state];
				}
			}
		}

		return (
			<select
				name='shippingState'
				value={orderData.shippingState}
				onChange={onFieldUpdate}
				placeholder='Select State/Province'
				className={formErrors.shippingState && 'error'}
			>
				<option value='' disabled>
					State/Province
				</option>
				{Object.entries(states[orderData.shippingCountry]).map(([abbr, name], index) => (
					<option key={index} value={abbr}>
						{name}
					</option>
				))}
			</select>
		);
	}

	function renderCheckbox() {
		return (
			<div className='sms-text'>
				<Checkbox
					label='Text me shipping updates & exclusive discounts'
					checked={orderData.agreeSms}
					onChange={onSmsCheckboxChange}
				/>
				{orderData.agreeSms && (
					<div className='sms-text-description'>
						By clicking this checkbox, you agree to receive marketing text messages from our company at the
						number provided. Consent is not a condition of purchase. Message and data rates may apply. Message
						frequency varies. Reply HELP for help or STOP to cancel. View our Privacy Policy and Terms of
						Service.
					</div>
				)}
			</div>
		);
	}

	return (
		<section className='shipping-info'>
			<StepHeader isOneLineCheckout={isOneLineCheckout} number={2} text='Enter Shipping Address' />

			<div className='form'>
				<div className='form__field'>
					<input
						type='text'
						name='email'
						value={orderData.email}
						onChange={onFieldUpdate}
						placeholder='Email'
						className={formErrors.email && 'error'}
					/>
					{formErrors.email && <div className='form__error'>{formErrors.email}</div>}
				</div>
				<div className='form__group'>
					<div className='form__field'>
						<input
							type='text'
							placeholder='First Name'
							value={orderData.firstName}
							name='firstName'
							onChange={onFieldUpdate}
							className={formErrors.firstName && 'error'}
						/>
						{formErrors.firstName && <div className='form__error'>{formErrors.firstName}</div>}
					</div>
					<div className='form__field'>
						<input
							type='text'
							placeholder='Last Name'
							value={orderData.lastName}
							name='lastName'
							onChange={onFieldUpdate}
							className={formErrors.lastName && 'error'}
						/>
						{formErrors.lastName && <div className='form__error'>{formErrors.lastName}</div>}
					</div>
				</div>
				<div className='form__field'>
					<input
						type='text'
						placeholder='Address'
						value={orderData.shippingAddress1}
						name='shippingAddress1'
						onChange={onFieldUpdate}
						className={formErrors.shippingAddress1 && 'error'}
					/>
					{formErrors.shippingAddress1 && <div className='form__error'>{formErrors.shippingAddress1}</div>}
				</div>
				<div className='form__group'>
					<div className='form__field'>
						<input
							type='text'
							placeholder='City'
							value={orderData.shippingCity}
							name='shippingCity'
							onChange={onFieldUpdate}
							className={formErrors.shippingCity && 'error'}
						/>
						{formErrors.shippingCity && <div className='form__error'>{formErrors.shippingCity}</div>}
					</div>
					<div className='form__field'>
						{renderStateSelect()}
						{formErrors.shippingState && <div className='form__error'>{formErrors.shippingState}</div>}
					</div>
				</div>
				<div className='form__group'>
					<div className='form__field'>
						{renderCountrySelect()}
						{formErrors.shippingCountry && <div className='form__error'>{formErrors.shippingCountry}</div>}
					</div>
					<div className='form__field'>
						<input
							type='text'
							placeholder='Zip/Postal'
							value={orderData.shippingZip}
							name='shippingZip'
							onChange={onFieldUpdate}
							className={formErrors.shippingZip && 'error'}
						/>
						{formErrors.shippingZip && <div className='form__error'>{formErrors.shippingZip}</div>}
					</div>
				</div>
				<div className='form__field'>
					<NumberFormat
						placeholder='Phone Number'
						format='(###) ###-####'
						mask='_'
						value={orderData.phone}
						name='phone'
						onChange={onFieldUpdate}
						className={formErrors.phone && 'error'}
					/>
					{formErrors.phone && <div className='form__error'>{formErrors.phone}</div>}
				</div>
				{renderCheckbox()}
			</div>

			{!isOneLineCheckout && (
				<>
					<button className='big-green-btn' onClick={() => handleSubmit()}>
						Next Step <i className='fas fa-chevron-right'></i>
					</button>
					{!queryParams.productid && !queryParams.total && (
						<button onClick={() => onPressBack(orderData)} className='go-back'>
							<i className='fas fa-chevron-left'></i> Go Back
						</button>
					)}
					<ShipsInfo />
				</>
			)}
		</section>
	);
}

ShippingInfo.propTypes = {
	onPressBack: PropTypes.func,
	onSubmit: PropTypes.func,
	orderData: PropTypes.object,
};
