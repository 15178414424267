/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
import React, { useState, useReducer, useEffect } from 'react';
import Cookies from 'js-cookie';
import queryString from 'query-string';
import xssFilters from 'xss-filters';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import ProductSelect from '../ProductSelect/ProductSelect';
import ShippingInfo from '../ShippingInfo/ShippingInfo';
import PaymentInfo from '../PaymentInfo/PaymentInfo';
import CrossSells from '../CrossSells/CrossSells';
import orderReducer from '../../reducers/orderReducer';
import api from '../../api/api';
import { validateStep } from '../../utils/orderFormUtils';
import { buildQueryParams } from '../../utils/helpers';
import { errorMessages as shippingErrorMessages } from '../ShippingInfo/errorMessages';
import { klaviyoStartedCheckout } from '../../utils/klaviyo';
import PayPalButtons from '../PayPalButtons/PayPalButtons';
import { trackFacebookInitiateCheckout } from '../../utils/facebook';

import './OrderForm.sass';

export default function OrderForm({
  offer,
  crossSells,
  onCreateOrder,
  onPrivacyClick,
  onTermsClick,
  isOneLineCheckout,
  isOneProductCheckout,
  declineError,
}) {
  // eslint-disable-next-line no-restricted-globals
  const queryParams = buildQueryParams(location);
  const showPayPal = queryParams.paypal === '1' || offer.showPayPal;

  const [stepIndex, setStepIndex] = useState(1);
  const [formErrors, setFormErrors] = useState({});
  const [clientToken, setClientToken] = useState(null);

  useEffect(() => {
    (async () => {
      const { data } = await api.get(`/braintree/token?merchantAccountId=${offer.payPalMerchantAccountId}`);
      const clientToken = data.clientToken;
      setClientToken(clientToken);
    })();
  }, []);

  // if it's prefilled checkout directly from interstitial
  useEffect(() => {
    if (queryParams.productid && queryParams.total && offer.productsData) {
      const selected = offer.productsData.find((item) => item.id === queryParams.productid);
      const upsellCount = queryParams.upsellproductids
        ? queryParams.upsellproductids.split(',').length + 1
        : 1;
      const dynamicProductsData = getDynamicProductsData(queryParams.upsellproductids.split(','));

      handleSaveStep(
        {
          ...orderData,
          productId: selected.id,
          productQty: selected.quantity,
          productName: selected.name,
          productPrice: selected.price,
          orderTotal: queryParams.total * 1,
          upsellCount,
          // upsellProductIds: `2170${queryParams.upsellproductids ? `,${queryParams.upsellproductids}` : ''}`,
          dynamicProductsData,
        },
        2
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offer, queryParams.productid]);

  function getDynamicProductsData(pids) {
    const result = {};

    pids.forEach((pid) => {
      if (result[`product_qty_${pid}`]) {
        result[`product_qty_${pid}`] += 1;
      } else {
        result[`product_qty_${pid}`] = 1;
      }
    });

    return result;
  }

  function getFbcValue() {
    if (Cookies.get('_fbc')) {
      return Cookies.get('_fbc');
    }

    if (queryParams.fbclid) {
      const timestamp = new Date().getTime();
      return `fb.1.${timestamp}.${queryParams.fbclid}`;
    }

    return '';
  }

  function getCustomFields() {
    const result = [];
    const fields = {
      17: queryString.parse(location.search).wbraid,
      16: queryString.parse(location.search).gbraid,
      15: queryString.parse(location.search).gclid,
    };

    Object.keys(fields).forEach((key) => {
      if (fields[key]) {
        result.push({
          id: key,
          value: fields[key],
        });
      }
    });

    return result;
  }

  const initialOrderState = {
    productId: offer.productsData[0].id || '',
    productQty: offer.productsData[0].quantity || '',
    productName: offer.productsData[0].name || '',
    productPrice: offer.productsData[0].price || '',
    email: '',
    firstName: '',
    lastName: '',
    shippingAddress1: '',
    shippingCity: '',
    shippingState: '',
    shippingCountry: 'US',
    shippingZip: '',
    phone: '',
    creditCardNumber: '',
    expirationDate: '',
    CVV: '',
    billingFirstName: '',
    billingLastName: '',
    billingAddress1: '',
    billingCity: '',
    billingState: '',
    billingCountry: 'US',
    billingZip: '',
    campaignId: offer.campaignId,
    shippingId: offer.productsData[0].shippingId || '',
    shippingPrice: offer.productsData[0].shippingPrice || '',
    billingSameAsShipping: true,
    agreeSms: false,
    fbc: getFbcValue(),
    fbp: Cookies.get('_fbp'),
    custom_fields: getCustomFields(),
    orderTotal: 0,
    dynamicProductsData: {},
    offers: [],
    brand: offer.brand,
  };

  const [orderData, dispatch] = useReducer(orderReducer, initialOrderState);

  useEffect(() => {
    // Start with the initial product
    const defaultProduct = offer?.productsData?.find((item) => item?.id === offer?.defaultVariantId);
    const initialProduct = defaultProduct || offer.productsData[0];
    const initialProductDetail = offer.productDetails[initialProduct.id];

    const newOffers = [
      {
        billing_model_id: initialProductDetail?.billingModelId || initialProduct.billingModelId,
        offer_id: initialProductDetail?.offerId || initialProduct.offerId,
        product_id: initialProduct.id,
        price: initialProduct.price,
        productQty: initialProduct.quantity,
        productName: initialProduct.name,
        isMainProduct: true,
      },
    ];

    // Add preselected cross-sells if they exist
    if (crossSells.length > 0) {
      const preSelected = crossSells.filter((item) => item.preselectedOffers.length);

      preSelected.forEach((item) => {
        let itemToAdd = item;

        if (item.variants.length > 0) {
          itemToAdd = item.variants[0];
        }

        newOffers.push({
          billing_model_id: itemToAdd.productBillingModelId,
          offer_id: itemToAdd.productOfferId,
          product_id: itemToAdd.productId,
          price: item.price,
          productName: itemToAdd.productName || itemToAdd.name,
          productQty: '1',
          isCrossSell: true,
        });
      });
    }

    // Dispatch the update once with the new array of offers
    dispatch({
      type: 'FIELD_UPDATE',
      field: 'offers',
      payload: newOffers,
    });

    trackFacebookInitiateCheckout(newOffers, queryParams);
  }, [offer.productsData, offer.productDetails, crossSells]);

  useEffect(() => {
    dispatch({
      type: 'FIELD_UPDATE',
      field: 'orderTotal',
      payload: orderData.offers.reduce((acc, item) => acc + item.price * 1, 0),
    });
  }, [orderData.offers]);

  // Everflow conversion ADD TO CART
  useEffect(() => {
    window?.EF?.conversion({
      aid: 1,
      adv_event_id: 4,
      order: { items: [] },
    });
  }, []);

  function handleSaveStep(data, newIndex) {
    dispatch({
      type: 'SAVE_STEP',
      payload: data,
    });

    if (newIndex === 2) {
      klaviyoStartedCheckout(data);
    }

    if (newIndex <= 3) {
      setStepIndex(newIndex);
    }

    if (newIndex === 4) {
      onCreateOrder(data);
    }
  }

  function handleFieldUpdate(e) {
    dispatch({
      type: 'FIELD_UPDATE',
      field: e.target.name,
      payload: xssFilters.inHTMLData(e.target.value),
    });
  }

  function handleSmsCheckboxChange(e) {
    dispatch({
      type: 'FIELD_UPDATE',
      field: 'agreeSms',
      payload: e.target.checked,
    });
  }

  function handlePressBack(stepData) {
    handleSaveStep(stepData, stepIndex - 1);
    setStepIndex(stepIndex - 1);
  }

  function handleSubmitStep(newData, errors, newStepIndex) {
    let newErrors = errors;

    if (isOneLineCheckout) {
      const shippingErrors = validateStep(orderData, shippingErrorMessages);

      if (Object.keys(shippingErrors).length > 0) {
        newErrors = { ...errors, ...shippingErrors };
      }
    }

    setFormErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      handleSaveStep({ ...orderData, ...newData }, newStepIndex);
    }
  }

  function updateOffers(item, meta) {
    const newOffers = [...orderData.offers];
    const existingItem = orderData.offers.find((o) => o.product_id * 1 === item.productId * 1);

    if (existingItem) {
      newOffers.splice(newOffers.indexOf(existingItem), 1);
    } else {
      newOffers.push({
        billing_model_id: item.productBillingModelId,
        offer_id: item.productOfferId,
        product_id: item.productId,
        price: item.price,
        productName: item.productName || item.name,
        ...meta,
      });
    }

    dispatch({
      type: 'SAVE_STEP',
      payload: {
        offers: newOffers,
      },
    });
  }

  function handleCrossSellChange(crossSell) {
    updateOffers(crossSell, { isCrossSell: true });
  }

  function handleUpgradeChange(upgrade) {
    updateOffers(upgrade, { isCrossSell: true });
  }

  function handleExpressApprove(data) {
    onCreateOrder(data);
  }

  function renderSteps() {
    const steps = [
      <div key='ProductSelect'>
        <ProductSelect
          offer={offer}
          products={offer.productsData}
          orderData={orderData}
          onSubmit={handleSaveStep}
          defaultVariantId={offer.defaultVariantId}
          key='ProductSelect'
          isOneLineCheckout={isOneLineCheckout}
          isOneProductCheckout={isOneProductCheckout}
        />
        {!isOneProductCheckout && crossSells.length > 0 && (
          <CrossSells
            offerId={offer._id}
            offers={orderData.offers}
            crossSells={crossSells}
            onChange={handleCrossSellChange}
          />
        )}
      </div>,
      <ShippingInfo
        key='ShippingInfo'
        orderData={orderData}
        onPressBack={handlePressBack}
        onFieldUpdate={handleFieldUpdate}
        onSmsCheckboxChange={handleSmsCheckboxChange}
        isOneLineCheckout={isOneLineCheckout}
        onSubmitStep={handleSubmitStep}
        formErrors={formErrors}
      />,
      <PaymentInfo
        key='PaymentInfo'
        orderData={orderData}
        onSubmit={handleSaveStep}
        onPressBack={handlePressBack}
        onFieldUpdate={handleFieldUpdate}
        onTermsClick={onTermsClick}
        onPrivacyClick={onPrivacyClick}
        isOneLineCheckout={isOneLineCheckout}
        webtvDisclaimer={offer.webtvDisclaimer}
        onSubmitStep={handleSubmitStep}
        formErrors={formErrors}
        onUpgradeChange={handleUpgradeChange}
        declineError={declineError}
      />,
    ];

    if (isOneLineCheckout) {
      return steps;
    } else {
      return steps[stepIndex - 1];
    }
  }

  return (
    <div className='order-form'>
      {clientToken && showPayPal && (
        <PayPalScriptProvider
          options={{
            vault: true,
            clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
            components: 'buttons',
            dataClientToken: clientToken,
          }}
        >
          <PayPalButtons
            offer={offer}
            orderData={orderData}
            checkoutTotal={orderData.orderTotal}
            onApprove={handleExpressApprove}
          />
        </PayPalScriptProvider>
      )}
      {renderSteps()}
    </div>
  );
}
