import { useState } from 'react';
import styles from './Upgrades.module.sass';
import shippingImage from './img/shipping.webp';
import warrantyImage from './img/warranty.webp';
import Checkbox from '../Form/Checkbox/Checkbox';

export default function Upgrades({ offers, onAdd }) {
	const upgrades = [
		{
			active: true,
			productId: '275',
			productOfferId: 1,
			productBillingModelId: 2,
			price: 9.95,
			description:
				'Want to get express shipping with your order? Add Expedited Shipping for just $9.95 for exclusive shipping and tracking service with delivery time in as little as 2-5 business days!',
			name: 'EXPEDITED SHIPPING',
			label: 'Add Expedited Shipping for ONLY $9.95!',
			image: shippingImage,
		},
		{
			active: true,
			productId: '276',
			productOfferId: 1,
			productBillingModelId: 2,
			price: 9.95,
			description:
				'Add full coverage for accidental damages, including drops, spills, tears, and more, with 24/7support when you need it. Quick, easy, and frustration-free replacement applies to all Knee Compression Sleeves in your order.',
			name: 'LIFETIME PROTECTION',
			label: 'Add Extended Lifetime Protection for ONLY $9.95!',
			image: warrantyImage,
		},
	];

	const Upgrade = ({ item }) => {
		const [isOpen, setOpen] = useState(false);

		function handleAdd() {
			onAdd(item);
		}

		const checked = offers.some((offer) => offer.product_id === item.productId);

		return (
			<div key={item.productId} className={styles.item}>
				<div className={styles.itemHeader}>
					<Checkbox label={item.label} id='upgrade' checked={checked} onChange={() => handleAdd(item)} />
					<div className={styles.itemLabel} onClick={() => setOpen(!isOpen)}>
						{isOpen ? 'Read Less' : 'Read More'}
					</div>
				</div>
				{isOpen && (
					<div className={styles.itemBody}>
						<img src={item.image} alt={item.name} />
						<div className={styles.itemDescription}>
							<b>{item.name}:</b> <span>{item.description}</span>
						</div>
					</div>
				)}
			</div>
		);
	};

	return (
		<div className={styles.container}>
			{upgrades.map((item) => (
				<Upgrade key={item.productId} item={item} />
			))}
		</div>
	);
}
