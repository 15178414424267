import React from 'react';
import shippingImage from './shipping.webp';
import satisfactionImage from './satisfaction.webp';
import flagImage from './flag.webp';
import styles from './TrustBadges.module.sass';

export default function TrustBadges({ brand }) {
	// TODO
	const queryString = window.location.search;

	const trustBadges = [
		{
			img: satisfactionImage,
			title: 'SATISFACTION GUARANTEE',
			text: 'If you are not 100% satisfied within 30 days, simply contact customer care for a full refund of your purchase price! (less S&H).',
		},
		{
			img: shippingImage,
			title: 'FAST SHIPPING',
			text: `Orders ship from the USA within <strong>1 business day</strong>${
				queryString.includes('upgrades') ? '.' : ', and most deliver in 2-3 days'
			} A tracking number will be sent to your email.`,
		},
		{ img: flagImage, title: 'AMERICAN OWNED', text: `${brand} is proud to be an American owned company.` },
	];

	return (
		<div className={styles.container}>
			{trustBadges.map((badge, index) => (
				<div className={styles.item} key={index}>
					<div className={styles.img}>
						<img src={badge.img} alt='trust' />
					</div>
					<div className={styles.text}>
						<b>{badge.title}</b>
						<p dangerouslySetInnerHTML={{ __html: badge.text }}></p>
					</div>
				</div>
			))}
		</div>
	);
}
