import './Footer.sass';

export default function Footer({ brand, openContactUsModal, openPrivacyModal, openTermsModal }) {
  const y = new Date().getFullYear();

  return (
    <>
      <footer className='page-footer'>
        <div className='page-footer__copyright'>
          &copy; {y} {brand}
        </div>
        <div className='page-footer__links'>
          <button className='page-footer__link' onClick={() => openContactUsModal()}>
            Contact Us
          </button>
          <button className='page-footer__link' onClick={() => openPrivacyModal()}>
            Privacy Policy
          </button>
          <button className='page-footer__link' onClick={() => openTermsModal()}>
            Terms of Service
          </button>
        </div>
        <div className='page-footer__note'>
          *Processing & handling fees will be applied to orders from all states in the USA.
        </div>
        <div className='page-footer__dmca'>
          <a
            href='//www.dmca.com/Protection/Status.aspx?ID=415d54d9-8752-453c-9318-7a6c1c5bd6ba'
            title='DMCA.com Protection Status'
            className='dmca-badge'
          >
            <img
              src='https://images.dmca.com/Badges/_dmca_premi_badge_5.png?ID=415d54d9-8752-453c-9318-7a6c1c5bd6ba'
              alt='DMCA.com Protection Status'
            />
          </a>
          <script src='https://images.dmca.com/Badges/DMCABadgeHelper.min.js'></script>
          <script src='https://js.braintreegateway.com/web/3.103.0/js/client.min.js' />
        </div>
      </footer>
    </>
  );
}
