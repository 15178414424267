import { BraintreePayPalButtons } from '@paypal/react-paypal-js';
import styles from './PayPalButtons.module.sass';

const style = {
  layout: 'vertical',
  color: 'gold',
  shape: 'rect',
  label: 'paypal',
};

export default function PayPalButtons({ checkoutTotal, onApprove, orderData, offer }) {
  return (
    <div className={styles.container}>
      <h2>Express Checkout</h2>
      <BraintreePayPalButtons
        style={style}
        fundingSource='paypal'
        merchantAccountId={offer.payPalMerchantAccountId}
        forceReRender={[style, checkoutTotal]}
        createBillingAgreement={(data, actions) => {
          return actions.braintree.createPayment({
            flow: 'vault',
            currency: 'USD',
            amount: checkoutTotal,
            enableShippingAddress: true,
            shippingAddressEditable: true,
            displayName: window.location.hostname.split('.').slice(-2).join('.'),
          });
        }}
        onApprove={async function (data, actions) {
          const payload = await actions.braintree.tokenizePayment(data);
          const newOrderData = {
            ...orderData,
            shippingCity: payload.details.shippingAddress.city,
            shippingAddress1: payload.details.shippingAddress.line1,
            shippingAddress2: payload.details.shippingAddress.line2,
            firstName: payload.details.firstName,
            lastName: payload.details.lastName,
            email: payload.details.email,
            shippingZip: payload.details.shippingAddress.postalCode,
            shippingState: payload.details.shippingAddress.state,
            phone: payload.details.phone || '0123456789',
            braintree_nonce: payload.nonce,
            creditCardType: 'paypal',
          };

          onApprove(newOrderData);
        }}
      />
    </div>
  );
}
