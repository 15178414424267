import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import StepHeader from '../StepHeader/StepHeader';
import ShipsInfo from '../ShipsInfo/ShipsInfo';
import NumberFormat from 'react-number-format';
import Checkbox from '../Form/Checkbox/Checkbox';
import { validateStep } from '../../utils/orderFormUtils';

import { states } from '../ShippingInfo/states';
import { errorMessages } from './errorMessages';
import { isExpDateValid } from '../../utils/orderFormUtils';
import { buildQueryParams } from '../../utils/helpers';
import { Context as OrderContext } from '../../context/OrderContext';
import TrustBadges from '../TrustBadges/TrustBadges';
import Upgrades from '../Upgrades/Upgrades';
import cvvImage from './cvv.jpeg';
import secure from './secure.png';

import './PaymentInfo.sass';

const months = [
  { label: 'Jan (01)', value: '01' },
  { label: 'Feb (02)', value: '02' },
  { label: 'Mar (03)', value: '03' },
  { label: 'Apr (04)', value: '04' },
  { label: 'May (05)', value: '05' },
  { label: 'Jun (06)', value: '06' },
  { label: 'Jul (07)', value: '07' },
  { label: 'Aug (08)', value: '08' },
  { label: 'Sep (09)', value: '09' },
  { label: 'Oct (10)', value: '10' },
  { label: 'Nov (11)', value: '11' },
  { label: 'Dec (12)', value: '12' },
];

const years = [
  { label: '2024', value: '24' },
  { label: '2025', value: '25' },
  { label: '2026', value: '26' },
  { label: '2027', value: '27' },
  { label: '2028', value: '28' },
  { label: '2029', value: '29' },
  { label: '2030', value: '30' },
  { label: '2031', value: '31' },
  { label: '2032', value: '32' },
  { label: '2033', value: '33' },
  { label: '2034', value: '34' },
];

export default function PaymentInfo(props) {
  // eslint-disable-next-line no-restricted-globals
  const queryParams = buildQueryParams(location);
  const {
    onPressBack,
    onFieldUpdate,
    orderData,
    onTermsClick,
    onPrivacyClick,
    onSubmitStep,
    formErrors,
    isOneLineCheckout,
    webtvDisclaimer,
    onUpgradeChange,
    declineError,
  } = props;
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [expMonth, setExpMonth] = useState('01');
  const [expYear, setExpYear] = useState(years[0].value);
  const [isBillingSame, setBillingSame] = useState(orderData.billingSameAsShipping);

  const showWebtvDisclaimer = queryParams.disclaimer === '1';
  const showUpgrades = queryParams.upgrades === '1';

  const {
    state: { error, loading },
  } = useContext(OrderContext);

  function preparePaymentInfo() {
    const billingInfo = {};
    let result = {};

    if (isBillingSame) {
      billingInfo.billingFirstName = orderData.firstName;
      billingInfo.billingLastName = orderData.lastName;
      billingInfo.billingAddress1 = orderData.shippingAddress1;
      billingInfo.billingCity = orderData.shippingCity;
      billingInfo.billingState = orderData.shippingState;
      billingInfo.billingCountry = orderData.shippingCountry;
      billingInfo.billingZip = orderData.shippingZip;
    }

    result = {
      ...orderData,
      creditCardNumber: orderData.creditCardNumber.replaceAll(' ', ''),
      expirationDate: expMonth + expYear,
      billingSameAsShipping: isBillingSame,
      ...billingInfo,
    };

    return result;
  }

  function handleSubmit() {
    const errors = validateStep(
      orderData,
      isBillingSame ? errorMessages[0] : { ...errorMessages[0], ...errorMessages[1] }
    );

    if (!agreeTerms) {
      errors.agreeTerms = 'You must agree to terms and conditions if you want to proceed';
    } else {
      delete errors.agreeTerms;
    }

    if (!isExpDateValid(expMonth, expYear)) {
      errors.expDateError = 'Please provide valid expiration date';
    } else {
      delete errors.expDateError;
    }

    onSubmitStep(preparePaymentInfo(), errors, 4);
  }

  function renderCountrySelect() {
    return (
      <select
        value={orderData.billingCountry}
        placeholder='Country'
        name='billingCountry'
        onChange={onFieldUpdate}
        className={formErrors.billingCountry && 'error'}
      >
        <option value='US'>United States</option>
        <option value='CA'>Canada</option>
      </select>
    );
  }

  function renderStateSelect() {
    return (
      <select
        name='billingState'
        value={orderData.billingState}
        onChange={onFieldUpdate}
        placeholder='Select State/Province'
        className={formErrors.billingState && 'error'}
      >
        <option value='' disabled>
          State/Province
        </option>
        {Object.entries(states[orderData.billingCountry]).map(([abb, name], index) => (
          <option key={index} value={abb}>
            {name}
          </option>
        ))}
      </select>
    );
  }

  function renderBillingForm() {
    const formBillingClassname = classNames({
      'form--billing': true,
      visible: !isBillingSame,
    });

    return (
      <div className='form form--noMargin'>
        <div className='form__header'>
          <Checkbox
            label='Billing same as shipping'
            checked={isBillingSame}
            onChange={() => setBillingSame(!isBillingSame)}
          />
        </div>
        <div className={formBillingClassname}>
          <div className='form__group'>
            <div className='form__field'>
              <input
                type='text'
                placeholder='First Name'
                value={orderData.billingFirstName}
                name='billingFirstName'
                onChange={onFieldUpdate}
                className={formErrors.billingFirstName && 'error'}
              />
              {formErrors.billingFirstName && (
                <div className='form__error'>{formErrors.billingFirstName}</div>
              )}
            </div>
            <div className='form__field'>
              <input
                type='text'
                placeholder='Last Name'
                value={orderData.billingLastName}
                name='billingLastName'
                onChange={onFieldUpdate}
                className={formErrors.billingLastName && 'error'}
              />
              {formErrors.billingLastName && <div className='form__error'>{formErrors.billingLastName}</div>}
            </div>
          </div>
          <div className='form__field'>
            <input
              type='text'
              placeholder='Address'
              value={orderData.billingAddress1}
              name='billingAddress1'
              onChange={onFieldUpdate}
              className={formErrors.billingAddress1 && 'error'}
            />
            {formErrors.billingAddress1 && <div className='form__error'>{formErrors.billingAddress1}</div>}
          </div>
          <div className='form__group'>
            <div className='form__field'>
              <input
                type='text'
                placeholder='City'
                value={orderData.billingCity}
                name='billingCity'
                onChange={onFieldUpdate}
                className={formErrors.billingCity && 'error'}
              />
              {formErrors.billingCity && <div className='form__error'>{formErrors.billingCity}</div>}
            </div>
            <div className='form__field'>
              {renderStateSelect()}
              {formErrors.billingState && <div className='form__error'>{formErrors.billingState}</div>}
            </div>
          </div>
          <div className='form__group'>
            <div className='form__field'>
              {renderCountrySelect()}
              {formErrors.billingCountry && <div className='form__error'>{formErrors.billingCountry}</div>}
            </div>
            <div className='form__field'>
              <input
                type='text'
                placeholder='Zip/Postal'
                value={orderData.billingZip}
                name='billingZip'
                onChange={onFieldUpdate}
                className={formErrors.billingZip && 'error'}
              />
              {formErrors.billingZip && <div className='form__error'>{formErrors.billingZip}</div>}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <section className='payment-info'>
      <StepHeader isOneLineCheckout={isOneLineCheckout} number={3} text='Payment' showCards={true} />
      <div className='form'>
        <div className='form__field'>
          <NumberFormat
            format='#### #### #### ####'
            name='creditCardNumber'
            value={orderData.creditCardNumber}
            onChange={onFieldUpdate}
            placeholder='Credit Card Number'
            className={formErrors.creditCardNumber && 'error'}
          />
          <i
            data-tip='All transactions are secured and encrypted'
            className='fas fa-lock form__field-icon'
          ></i>
          <ReactTooltip />
          {formErrors.creditCardNumber && <div className='form__error'>{formErrors.creditCardNumber}</div>}
        </div>
        <div className='form__group form__group--one-line'>
          <div className='form__field'>
            <select onChange={(e) => setExpMonth(e.target.value)} value={expMonth} name='Expiration month'>
              {months.map((item, index) => (
                <option key={index} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
            {formErrors.expDateError && <div className='form__error'>{formErrors.expDateError}</div>}
          </div>
          <div className='form__field'>
            <select onChange={(e) => setExpYear(e.target.value)} name='Expiration year' value={expYear}>
              {years.map((item, index) => (
                <option key={index} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
          </div>
          <div className='form__field'>
            <NumberFormat
              format='####'
              placeholder='CVV'
              value={orderData.CVV}
              name='CVV'
              onChange={onFieldUpdate}
              className={formErrors.CVV && 'error'}
            />
            <i
              data-tip={`<img src="${cvvImage}" style="min-width: 250px;" />`}
              className='far fa-question-circle form__field-icon'
              data-html={true}
            ></i>
            <ReactTooltip />
            {formErrors.CVV && <div className='form__error'>{formErrors.CVV}</div>}
          </div>
        </div>
      </div>

      {showUpgrades && <Upgrades offers={orderData.offers} onAdd={onUpgradeChange} />}
      {renderBillingForm()}
      <div className='form'>
        <Checkbox
          label={
            <div>
              I agree to the &nbsp;
              <span className='link' onClick={() => onTermsClick()}>
                Terms & Conditions
              </span>{' '}
              and{' '}
              <span className='link' onClick={() => onPrivacyClick()}>
                Privacy Policy
              </span>{' '}
              of this sale
            </div>
          }
          id='tc'
          checked={agreeTerms}
          onChange={() => setAgreeTerms(!agreeTerms)}
        />
        {formErrors.agreeTerms && <div className='form__error'>{formErrors.agreeTerms}</div>}
      </div>

      <button className='big-green-btn' onClick={() => handleSubmit()}>
        COMPLETE MY ORDER
        {loading ? <i className='fas fa-spinner fa-spin'></i> : <i className='fas fa-chevron-right'></i>}
      </button>

      {(error || declineError) && (
        <div className='processing-error'>
          <p>{error || declineError}</p>
        </div>
      )}
      {!isOneLineCheckout && (
        <button onClick={() => onPressBack(orderData)} className='go-back'>
          <i className='fas fa-chevron-left'></i> Go Back
        </button>
      )}
      <ShipsInfo />
      <img className='secure-image' src={secure} alt='secure' />
      <div className='payment-info-trust-badges'>
        <TrustBadges brand={orderData.brand} />
      </div>
      {showWebtvDisclaimer && (
        <p style={{ padding: '0 20px 20px', fontSize: '13px', lineHeight: 'normal' }}>{webtvDisclaimer}</p>
      )}
    </section>
  );
}

PaymentInfo.propTypes = {
  onPressBack: PropTypes.func,
  onSubmit: PropTypes.func,
  orderData: PropTypes.object,
};
