import React from 'react';
import './StepHeader.sass';
import cardTypesImage from './cardTypes.png';

export default function StepHeader({ number, text, showCards, isOneLineCheckout }) {
  return (
    <div className='step-header'>
      <div>
        {!isOneLineCheckout && <b>Step #{number}:</b>}
        {text}
      </div>
      {showCards && <img src={cardTypesImage} alt='card types' />}
    </div>
  );
}
