import styles from './MoneyBackGuarantee.module.sass';
import image from './guarantee.png';

export default function MoneyBackGuarantee() {
  return (
    <div className={styles.container}>
      <img src={image} alt='Money Back Guarantee' />
      <div className={styles.text}>
        <b>Money Back Guarantee</b>
        <p>Simply send your item back within 30 days and we’ll refund your full purchase (less S&H)</p>
      </div>
    </div>
  );
}
