import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { getPriceWithCurrency } from '../../utils/helpers';

export default function CrossSell({ crossSell, onChange, isND, offers, offer }) {
  const hasVariants = crossSell.variants.length > 0;
  const [selectedVariant, setSelectedVariant] = useState(null);
  const currentItem = hasVariants ? { ...crossSell, ...selectedVariant } : crossSell;

  useEffect(() => {
    if (hasVariants) {
      setSelectedVariant(crossSell.variants[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleSelectVariant(e) {
    const value = crossSell.variants.find((item) => item.productId === e.target.value);
    setSelectedVariant(value);
  }

  function handleAdd() {
    onChange(currentItem);
  }

  const active = offers.find((item) => item.product_id === currentItem.productId);

  if (isND) {
    const itemClasses = classNames({
      'cross-sells-item': true,
      'nd-cross-sells-item': true,
      active,
    });

    return (
      <div className={itemClasses}>
        <div className='cross-sells-item-left-badge'>
          <b>{currentItem.discount}% OFF</b>
          <span>Today</span>
        </div>

        <div className='cross-sells-item-inner'>
          <div className='cross-sells-item__img'>
            <img src={selectedVariant?.productImg || currentItem.imageSrc} alt={currentItem.name} />
            {hasVariants && (
              <div className='cross-sells-variant-selector'>
                <p>{currentItem.variants[0].name}:</p>
                <select onChange={handleSelectVariant} value={selectedVariant?.productId}>
                  {currentItem.variants.map((item, index) => (
                    <option key={index} value={item.productId}>
                      {item.value}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </div>
          <div className='cross-sells-item__description'>
            <h4>{selectedVariant?.productName || currentItem.title || currentItem.name}</h4>
            <ul>
              {currentItem.benefits.map((benefit, index) => (
                <li key={index}>
                  <i className='fas fa-check'></i> {benefit}
                </li>
              ))}
            </ul>
          </div>
          <div className='cross-sells-item__price' onClick={() => handleAdd()}>
            <img
              src='https://ik.imagekit.io/pcaztueon7s/Launchify/CrossSells/empty-checkmark_nOra5c9OVw.png?updatedAt=1631731001342'
              alt='empty check mark'
              className='cross-sells-item__price-empty-checkmark'
            />
            <img
              src='https://ik.imagekit.io/pcaztueon7s/Launchify/CrossSells/nd-check_rVKZn3OjK.png?ik-sdk-version=javascript-1.4.3&updatedAt=1666718943159'
              alt='check mark'
              className='cross-sells-item__price-checkmark'
            />
            <s>{getPriceWithCurrency(currentItem.msrp, offer?.locale, offer?.currency)}</s>
            <p>{getPriceWithCurrency(currentItem.price, offer?.locale, offer?.currency)}</p>
            <div className='cross-sells-item-right-badge'>{currentItem.stock}!</div>
          </div>
        </div>
      </div>
    );
  }

  const itemClasses = classNames({
    'cross-sells-item': true,
    active,
  });

  return (
    <div className={itemClasses}>
      <div className='cross-sells-item-left-badge'>
        <b>{currentItem.discount}% OFF</b>
        <span>Today Only</span>
      </div>
      <div className='cross-sells-item-right-badge'>
        <i className='fas fa-exclamation-triangle'></i>
        {currentItem.stock}
      </div>
      <div className='cross-sells-item-inner'>
        <div className='cross-sells-item__img'>
          <img src={selectedVariant?.productImg || currentItem.imageSrc} alt={currentItem.name} />
        </div>
        <div className='cross-sells-item__description'>
          <h4>{selectedVariant?.productName || currentItem.title || currentItem.name}</h4>
          <ul>
            {currentItem.benefits.map((benefit, index) => (
              <li key={index}>
                <i className='fas fa-check'></i> {benefit}
              </li>
            ))}
          </ul>
          {hasVariants && (
            <div className='cross-sells-variant-selector'>
              <p>{currentItem.variants[0].name}:</p>
              <select onChange={handleSelectVariant} value={selectedVariant?.productId}>
                {currentItem.variants.map((item, index) => (
                  <option key={index} value={item.productId}>
                    {item.value}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>
        <div className='cross-sells-item__price' onClick={() => handleAdd()}>
          <h4>{active ? 'Click to Remove' : 'Click to Add'} </h4>
          <img
            src='https://ik.imagekit.io/pcaztueon7s/Launchify/CrossSells/empty-checkmark_nOra5c9OVw.png?updatedAt=1631731001342'
            alt='empty check mark'
            className='cross-sells-item__price-empty-checkmark'
          />
          <img
            src='https://ik.imagekit.io/pcaztueon7s/Launchify/CrossSells/checkmark_rZn7aX0ui.png?updatedAt=1631731001329'
            alt='check mark'
            className='cross-sells-item__price-checkmark'
          />
          <s>{getPriceWithCurrency(currentItem.msrp, offer?.locale, offer?.currency)}</s>
          <p>{getPriceWithCurrency(currentItem.price, offer?.locale, offer?.currency)}</p>
        </div>
      </div>
    </div>
  );
}
