import './Banner.sass';
import moment from 'moment';

export default function Banner({ discount, banners }) {
  function getCurrentBanners() {
    return banners.filter((banner) => moment().isAfter(banner.start) && moment().isBefore(banner.end));
  }

  return getCurrentBanners().map((banner, index) => (
    <div className='holiday-banner' key={index}>
      <img
        className='holiday-banner__img holiday-banner__img--desk'
        src={banner.imageDesk}
        alt='desktop banner'
      />
      <img
        className='holiday-banner__img holiday-banner__img--mob'
        src={banner.imageMob}
        alt='mobile banner'
      />
      <div className='holiday-banner__discount'>
        Save Up To <span className='holiday-banner__percent'>{discount}</span> OFF
      </div>
    </div>
  ));
}
