import createDataContext from './createDataContext';
import api from '../api/api';
import CryptoJS from 'crypto-js';

const SECRET_KEY = process.env.REACT_APP_HMAC_SECRET_KEY;

const orderReducer = (state, action) => {
  switch (action.type) {
    case 'REQUEST':
      return {
        ...state,
        loading: true,
        error: false,
      };
    case 'SUCCESS':
      return {
        ...state,
        loading: false,
        order: action.payload,
      };
    case 'VERIFICATION_SUCCESS':
      return {
        ...state,
        loading: false,
        verification: action.payload,
      };
    case 'ERROR':
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

// Helper function to generate HMAC signature
const generateSignature = (data, timestamp) => {
  const message = `${JSON.stringify(data)}|${timestamp}`;
  return CryptoJS.HmacSHA256(message, SECRET_KEY).toString(CryptoJS.enc.Hex);
};

const createOrder = (dispatch) => async (data) => {
  try {
    dispatch({
      type: 'REQUEST',
    });

    const timestamp = new Date().toISOString();
    const signature = generateSignature(data, timestamp);

    const response = await api.post(`/orders/`, data, {
      headers: {
        'X-Signature': signature,
        'X-Timestamp': timestamp,
        'Content-Type': 'application/json',
      },
    });

    dispatch({
      type: 'SUCCESS',
      payload: response.data,
    });
    return response.data;
  } catch (e) {
    dispatch({
      type: 'ERROR',
      payload: `Unable to create order. ${e}`,
    });
  }
};

const updateOrder = (dispatch) => async (id, data) => {
  try {
    dispatch({
      type: 'REQUEST',
    });

    const timestamp = new Date().toISOString();
    const signature = generateSignature(data, timestamp);

    const response = await api.patch(`/orders/${id}`, data, {
      headers: {
        'X-Signature': signature,
        'X-Timestamp': timestamp,
        'Content-Type': 'application/json',
      },
    });

    dispatch({
      type: 'SUCCESS',
      payload: response.data,
    });
    return response.data;
  } catch (e) {
    dispatch({
      type: 'ERROR',
      payload: 'Unable to update order',
    });
  }
};

const verifyOrder = (dispatch) => async (id) => {
  try {
    dispatch({
      type: 'REQUEST',
    });
    const response = await api.put(`/orders/verify/${id}`);
    dispatch({
      type: 'VERIFICATION_SUCCESS',
      payload: response.data,
    });
    return response.data;
  } catch (e) {
    dispatch({
      type: 'ERROR',
      payload: 'Unable to verify order',
    });
  }
};

const loadOrder = (dispatch) => async (id) => {
  try {
    dispatch({
      type: 'REQUEST',
    });
    const response = await api.get(`/orders/${id}`);
    dispatch({
      type: 'SUCCESS',
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: 'ERROR',
      payload: 'Unable to load order',
    });
  }
};

export const { Context, Provider } = createDataContext(
  orderReducer,
  {
    createOrder,
    updateOrder,
    loadOrder,
    verifyOrder,
  },
  {
    order: {},
    error: '',
    loading: false,
    verification: null,
  }
);
