/* eslint-disable no-console */
import api from '../api/api';
import Cookies from 'js-cookie';

const CryptoJS = require('crypto-js');

function generateEventId() {
  return Math.random().toString(36).substring(2) + Date.now().toString();
}

function getHashedValue(string) {
  if (!string) return '';
  return CryptoJS.SHA256(string.toLowerCase()).toString(CryptoJS.enc.Hex);
}

export async function trackPinterestPageVisit(data) {
  const event = {
    event_name: 'page_visit',
    event_time: Math.floor(Date.now() / 1000),
    action_source: 'web',
    event_id: generateEventId(),
    event_source_url: window.location.href,
    user_data: {
      client_user_agent: data.userAgent,
      external_id: [getHashedValue(Cookies.get('launch_ra_id'))],
      click_id: data.clickId,
    },
  };

  try {
    const config = {
      method: 'post',
      url: '/pinterest/trackEvent',
      data: event,
    };

    const response = await api(config);
    return response.data;
  } catch (error) {
    console.log('Pinterest error: ', error);
    return 'Pinterest error: unable to track page view';
  }
}
