import createDataContext from './createDataContext';
import api from '../api/api';

const offerReducer = (state, action) => {
	switch (action.type) {
		case 'LOAD_OFFER_REQUEST':
			return {
				...state,
				loading: true,
				error: false,
			};
		case 'LOAD_OFFER_SUCCESS':
			return {
				...state,
				loading: false,
				offer: action.payload,
			};
		case 'MERGE_OFFER_WITH_PROMO':
			return {
				loading: false,
				...state,
				offer: {
					...state.offer,
					...action.payload,
				},
			};
		case 'LOAD_OFFER_ERROR':
			return {
				...state,
				loading: false,
				error: 'Unable to load offer',
			};
		default:
			return state;
	}
};

const loadOffer = (dispatch) => async (id) => {
	try {
		dispatch({
			type: 'LOAD_OFFER_REQUEST',
		});
		const response = await api.get(`/offers/${id}`);
		dispatch({
			type: 'LOAD_OFFER_SUCCESS',
			payload: response.data,
		});
		return response.data;
	} catch (e) {
		dispatch({
			type: 'LOAD_OFFER_ERROR',
		});
	}
};

const mergeOfferWithPromo = (dispatch) => async (promo) => {
	const promoUpdates = {};

	Object.keys(promo).forEach((key) => {
		if (
			(Array.isArray(promo[key]) && promo[key].length > 0) ||
			(typeof promo[key] === 'object' && promo[key] !== null && Object.keys(promo[key]).length > 0)
		) {
			promoUpdates[key] = promo[key];
		}
	});

	dispatch({
		type: 'MERGE_OFFER_WITH_PROMO',
		payload: promoUpdates,
	});
};

export const { Context, Provider } = createDataContext(
	offerReducer,
	{
		mergeOfferWithPromo,
		loadOffer,
	},
	{
		offer: {},
		error: '',
		loading: false,
	}
);
