/* eslint-disable no-restricted-globals */
import React, { useContext, useState, useEffect, useRef } from 'react';
import queryString from 'query-string';

import { Context as AppContext } from '../../context/AppContext';
import TrustBadges from '../TrustBadges/TrustBadges';
import './ProductInfo.sass';

export default function ProductInfo(props) {
	const { offer } = props;
	const [activeItem, setActiveItem] = useState(null);
	const parsedQuery = queryString.parse(location.search);
	const currentCount = useRef(offer.ratings * 1);
	const [delivered, setDelivered] = useState(offer.ratings * 1);

	// Context
	const {
		state: { selectedColor },
	} = useContext(AppContext);

	useEffect(() => {
		if (offer.gallery && offer.gallery[0].source) {
			setActiveItem(offer.gallery[0]);
		}
	}, [offer.gallery]);

	useEffect(() => {
		const timer = setInterval(() => {
			function getRandomInt(max) {
				return Math.floor(Math.random() * max);
			}

			const random = getRandomInt(3);
			currentCount.current = currentCount.current + random;

			setDelivered(currentCount.current);
		}, 2000);

		return () => clearTimeout(timer);
	}, []);

	function handleThumbClick(thumbnail) {
		setActiveItem(offer.gallery.find((item) => item.thumbnail === thumbnail));
	}

	function renderGallery() {
		const galleryItem = offer.gallery[0];

		if (!activeItem) {
			return null;
		}

		return (
			<div>
				{galleryItem.source && (
					<div className='product-gallery'>
						<div className='product-gallery__active'>
							{activeItem.type === 'image' ? (
								<img src={activeItem.source} alt='' />
							) : (
								<video
									playsInline
									autoPlay
									muted
									poster={activeItem.thumbnail}
									controls
									src={activeItem.source}
								></video>
							)}
						</div>

						<div className='product-gallery-thumbs'>
							{offer.gallery.map((item, index) => (
								<div
									key={index}
									className='product-gallery-thumbs__item'
									onClick={() => handleThumbClick(item.thumbnail)}
								>
									<img src={item.thumbnail} alt='thumbnail' />
								</div>
							))}
						</div>
					</div>
				)}
			</div>
		);
	}

	function renderProductDetails() {
		return (
			<div>
				{parsedQuery.carousel === '1' && renderGallery()}
				<div className='product-details'>
					<div className='product-details__left'>
						<div className='product-details__title'>{offer.title}</div>
						<div className='product-details__mfr'>
							by <span>{offer.brand}</span>
						</div>
						<div className='product-details__ratings'>
							<i className='fas fa-star'></i>
							<i className='fas fa-star'></i>
							<i className='fas fa-star'></i>
							<i className='fas fa-star'></i>
							<i className='fas fa-star'></i>
							<span>{delivered}+ Delivered</span>
						</div>
						<div className='product-details__best-seller'>#1 Best Seller</div>
						<div className='product-details__category'>in {offer.category}</div>
					</div>
					{parsedQuery.carousel !== '1' && (
						<img
							src={selectedColor && selectedColor.name ? selectedColor.imageSrc : offer.productImageSrc}
							alt='product'
							className='product-details__img'
						/>
					)}
				</div>
			</div>
		);
	}

	function renderProductBenefits() {
		return (
			<div className='product-benefits'>
				{offer.benefits.map((item, index) => (
					<div className='product-benefits__item' key={index}>
						<i className='fas fa-check-circle'></i> {item}
					</div>
				))}
			</div>
		);
	}

	return (
		<section className='product-info'>
			{renderProductDetails()}
			{renderProductBenefits()}
			<div className='desktop-only'>
				<TrustBadges brand={offer.brand} />
			</div>
		</section>
	);
}
