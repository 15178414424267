/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */

import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import queryString from 'query-string';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import NumberFormat from 'react-number-format';
import api from '../../api/api';
import { states } from '../../components/ShippingInfo/states';
import PayPalButtons from '../../components/PayPalButtons/PayPalButtons';
import Banner from '../../components/Banner/Banner';

import moment from 'moment';

import {
  buildQueryParams,
  getMaxDiscount,
  getPriceWithCurrency,
  getSavingsPercent,
  mapColorsFromProducts,
} from '../../utils/helpers';
import visa from './img/Template3/visa.webp';
import master from './img/Template3/master.webp';
import amex from './img/Template3/amex.webp';
import safe from './img/Template3/safe.webp';
import styles from './Template3.module.sass';
import ColorSelect from '../../components/ColorSelect/ColorSelect';
import CrossSells from '../../components/CrossSells/CrossSells';
import MoneyBackGuarantee from '../../components/MoneyBackGuarantee/MoneyBackGuarantee';

function getFbcValue(queryParams) {
  if (Cookies.get('_fbc')) {
    return Cookies.get('_fbc');
  }

  if (queryParams.fbclid) {
    const timestamp = new Date().getTime();
    return `fb.1.${timestamp}.${queryParams.fbclid}`;
  }

  return '';
}

function getCustomFields() {
  const result = [];
  const fields = {
    17: queryString.parse(location.search).wbraid,
    16: queryString.parse(location.search).gbraid,
    15: queryString.parse(location.search).gclid,
  };

  Object.keys(fields).forEach((key) => {
    if (fields[key]) {
      result.push({
        id: key,
        value: fields[key],
      });
    }
  });

  return result;
}

function makePlural(str, quantity) {
  if (quantity === 1 || str.toLowerCase().endsWith('s')) {
    return str;
  } else {
    return `${str}s`;
  }
}

const validationMessages = {
  email: 'Enter valid email address.',
  phone: 'Enter valid phone number.',
  creditCardNumber: 'Enter valid credit card number.',
  firstName: 'First name is required.',
  lastName: 'Last name is required.',
  shippingAddress1: 'Shipping address is required.',
  shippingCity: 'City is required.',
  shippingState: 'This Field is required.',
  shippingZip: 'ZIP code is required.',
  expirationMonth: 'Select a valid expiration month.',
  expirationYear: 'Select a valid expiration year.',
  CVV: 'Enter valid CVV.',
  isTermsAgreed: 'You must agree to the terms.',
};

// Validate a single field
function validateField(fieldName, value) {
  switch (fieldName) {
    case 'email':
      if (!value || !/\S+@\S+\.\S+/.test(value)) {
        return validationMessages.email;
      }
      break;
    case 'phone':
      if (!value || !/^\d{10}$/.test(value.replace(/\D/g, ''))) {
        return validationMessages.phone;
      }
      break;
    case 'creditCardNumber':
      if (!value || !/^\d{13,19}$/.test(value.replace(/\s+/g, ''))) {
        return validationMessages.creditCardNumber;
      }
      break;
    case 'firstName':
      if (!value) {
        return validationMessages.firstName;
      }
      break;
    case 'lastName':
      if (!value) {
        return validationMessages.lastName;
      }
      break;
    case 'shippingAddress1':
      if (!value) {
        return validationMessages.shippingAddress1;
      }
      break;
    case 'shippingCity':
      if (!value) {
        return validationMessages.shippingCity;
      }
      break;
    case 'shippingState':
      if (!value) {
        return validationMessages.shippingState;
      }
      break;
    case 'shippingZip':
      if (!value) {
        return validationMessages.shippingZip;
      }
      break;
    case 'expirationMonth':
      if (!value || isNaN(value) || value < 1 || value > 12) {
        return validationMessages.expirationMonth;
      }
      break;
    case 'expirationYear':
      if (!value || isNaN(value)) {
        return validationMessages.expirationYear;
      }
      break;
    case 'CVV':
      if (!value || !/^\d{3,4}$/.test(value)) {
        return validationMessages.CVV;
      }
      break;
    case 'isTermsAgreed':
      if (!value) {
        return validationMessages.isTermsAgreed;
      }
      break;
    default:
      break;
  }

  return null; // No error
}

const validateForm = (data) => {
  const errors = {};
  Object.keys(validationMessages).forEach((field) => {
    const error = validateField(field, data[field]);
    if (error) {
      errors[field] = error;
    }
  });

  // Additional validation for combined expiration date
  if (!errors.expirationMonth && !errors.expirationYear) {
    const expDate = moment(`${data.expirationMonth}-${data.expirationYear}`, 'MM-YYYY', true).endOf('month');
    const currentDate = moment();

    if (!expDate.isValid() || expDate.isBefore(currentDate, 'day')) {
      errors.expirationMonth = validationMessages.expirationMonth;
      errors.expirationYear = validationMessages.expirationYear;
    }
  }
  return errors;
};

export default function Template3({
  offer,
  isLoading,
  crossSells,
  onTermsClick,
  onCreateOrder,
  declineError,
}) {
  const [banners, setBanners] = useState([]);
  const queryParams = buildQueryParams(location);
  const showPayPal = queryParams.paypal === '1' || offer.showPayPal;

  const mergedProductsData = offer.productsData.map((product) => {
    const productDetail = offer.productDetails[product.id];

    return {
      ...product,
      ...productDetail,
    };
  });

  const defaultProduct = mergedProductsData.find((item) => item?.id === offer?.defaultVariantId);
  const initialProduct = defaultProduct || mergedProductsData[0];

  const defaultVariantId = offer.defaultVariantId;
  const someProductIsSubscription = mergedProductsData.some((item) => item.billingModelId !== '2');

  const [clientToken, setClientToken] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedColor, setSelectedColor] = useState(null);
  const [selectedOfferType, setSelectedOfferType] = useState('subscription');

  const [errors, setErrors] = useState({});
  const [timeLeft, setTimeLeft] = useState({
    hours: 0,
    minutes: 15,
    seconds: 0,
  });
  const [state, setState] = useState({
    email: '',
    firstName: '',
    lastName: '',
    shippingAddress1: '',
    shippingCity: '',
    shippingState: 'AL',
    shippingCountry: offer?.shippingCountries[0]?.id || 'US',
    shippingZip: '',
    phone: '',
    creditCardNumber: '',
    expirationMonth: '',
    expirationYear: '',
    CVV: '',
    billingFirstName: '',
    billingLastName: '',
    billingAddress1: '',
    billingCity: '',
    billingState: '',
    billingCountry: 'US',
    billingZip: '',
    billingSameAsShipping: true,
    brand: offer.brand,
    isTermsAgreed: true,
    productId: initialProduct.id,
    productQty: initialProduct.quantity,
    productName: initialProduct.name,
    productPrice: initialProduct.price,
    agreeSms: false,
    fbc: getFbcValue(queryParams),
    fbp: Cookies.get('_fbp'),
    custom_fields: getCustomFields(),
    orderTotal: initialProduct.price * 1 + initialProduct.shippingPrice * 1,
    campaignId: offer.campaignId,
    shippingId: initialProduct.shippingId || '',
    shippingPrice: initialProduct.shippingPrice || '',
    offers: [
      {
        product_id: initialProduct.id,
        offer_id: initialProduct.offerId,
        billing_model_id: initialProduct.billingModelId,
        price: initialProduct.price,
        productQty: initialProduct.quantity,
        productName: initialProduct.name,
        isMainProduct: true,
      },
    ],
  });

  async function getBanners() {
    try {
      const response = await api.get('/banners');
      if (response.data) {
        setBanners(response.data);
      }
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  useEffect(() => {
    if (!offer.isBannerDisabled) {
      getBanners();
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const filteredProducts = filterProducts(mergedProductsData);
    const newSelectedProduct =
      filteredProducts.find(
        (item) =>
          item.quantity === selectedProduct?.quantity &&
          item.color === selectedColor?.name &&
          (someProductIsSubscription
            ? item.billingModelId ===
              (selectedOfferType === 'subscription' ? selectedProduct?.billingModelId : '2')
            : true)
      ) || filteredProducts[0];

    if (
      newSelectedProduct &&
      newSelectedProduct?.id !== selectedProduct?.id &&
      newSelectedProduct.soldOut !== '1'
    ) {
      setSelectedProduct(newSelectedProduct);
    } else if (newSelectedProduct?.soldOut === '1') {
      setSelectedProduct(null);
    }
  }, [selectedOfferType, selectedColor]);

  useEffect(() => {
    const stickyOffer = {
      billing_model_id: selectedProduct?.billingModelId,
      offer_id: selectedProduct?.offerId,
      product_id: selectedProduct?.id,
      price: selectedProduct?.price,
      productQty: selectedProduct?.quantity,
      productName: selectedProduct?.name,
      isMainProduct: true,
    };

    const currentOffers = state.offers || [];
    const newOffers = currentOffers.filter((offer) => !offer.isMainProduct);
    newOffers.push(stickyOffer);

    const offersTotal = newOffers.reduce((total, offer) => total + offer.price * 1, 0);
    const orderTotal = offersTotal + selectedProduct?.shippingPrice * 1;

    setState({
      ...state,
      orderTotal,
      offers: newOffers,
      shippingId: selectedProduct?.shippingId,
      shippingPrice: selectedProduct?.shippingPrice,
      productId: selectedProduct?.id,
      productQty: selectedProduct?.quantity,
      productName: selectedProduct?.name,
      productPrice: selectedProduct?.price,
    });
  }, [selectedProduct]);

  useEffect(() => {
    if (crossSells.length > 0) {
      const preSelected = crossSells.filter((item) => item.preselectedOffers.some((o) => o.id === offer._id));

      if (preSelected.length > 0) {
        const crossSellOffers = [];

        preSelected.forEach((item) => {
          let itemToAdd = item;

          if (item.variants && item.variants.length > 0) {
            itemToAdd = item.variants[0];
          }

          crossSellOffers.push({
            billing_model_id: itemToAdd.productBillingModelId,
            offer_id: itemToAdd.productOfferId,
            product_id: itemToAdd.productId,
            price: itemToAdd.price,
            productName: itemToAdd.productName || itemToAdd.name,
            productQty: '1',
            isCrossSell: true,
          });
        });

        setState((prevState) => {
          const currentOffers = prevState.offers || [];
          const offersWithoutPreselectedCrossSells = currentOffers.filter(
            (offer) => !(offer.isCrossSell && offer.preselected)
          );
          const newOffers = [...offersWithoutPreselectedCrossSells, ...crossSellOffers];

          const offersTotal = newOffers.reduce((total, offer) => total + offer.price * 1, 0);
          const orderTotal = offersTotal + selectedProduct?.shippingPrice * 1;

          return {
            ...prevState,
            orderTotal,
            offers: newOffers,
          };
        });
      }
    }
  }, [crossSells]);

  useEffect(() => {
    const products = mergedProductsData;
    let defaultProduct = products[0];

    if (products?.length > 0) {
      setSelectedProduct(defaultProduct);
    }

    if (defaultVariantId) {
      defaultProduct = products.find((item) => item.id === defaultVariantId);
      defaultProduct && setSelectedProduct(defaultProduct);
    }

    if (defaultVariantId && !selectedColor && defaultProduct) {
      handleColorSelect({
        name: defaultProduct.color,
        imageSrc: defaultProduct.colorImage || defaultProduct.colorimage,
      });
    }

    if (products && defaultProduct) {
      setSelectedColor(
        selectedColor
          ? selectedColor
          : {
              name: defaultProduct.color,
              imageSrc: defaultProduct.colorImage || defaultProduct.colorimage,
            }
      );
    }
  }, [offer.productsData]);

  useEffect(() => {
    const anotherProductWithSameQuantity = mergedProductsData.find(
      (item) => item.quantity === selectedProduct?.quantity && item.id !== selectedProduct?.id
    );

    if (anotherProductWithSameQuantity) {
      setSelectedProduct(anotherProductWithSameQuantity);
    }
  }, [selectedOfferType]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft((prevTime) => {
        let { hours, minutes, seconds } = prevTime;

        if (seconds > 0) {
          seconds -= 1;
        } else if (minutes > 0) {
          seconds = 59;
          minutes -= 1;
        } else if (hours > 0) {
          seconds = 59;
          minutes = 59;
          hours -= 1;
        } else {
          clearInterval(intervalId); // Stop the countdown when it reaches zero
        }

        return { hours, minutes, seconds };
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    (async () => {
      const { data } = await api.get(`/braintree/token?merchantAccountId=${offer.payPalMerchantAccountId}`);
      const clientToken = data.clientToken;
      setClientToken(clientToken);
    })();
  }, []);

  useEffect(() => {
    if (state.shippingCountry === 'US' || state.shippingCountry === 'CA') {
      setState({ ...state, shippingState: 'AL' });
    } else {
      setState({ ...state, shippingState: '' });
    }
  }, [state.shippingCountry]);

  // Helpers

  function updateOffers(item, meta) {
    const newOffers = [...state.offers];
    const existingItem = state.offers.find((o) => o.product_id * 1 === item.productId * 1);

    if (existingItem) {
      newOffers.splice(newOffers.indexOf(existingItem), 1);
    } else {
      newOffers.push({
        billing_model_id: item.productBillingModelId,
        offer_id: item.productOfferId,
        product_id: item.productId,
        price: item.price,
        productName: item.productName || item.name,
        ...meta,
      });
    }

    setState({ ...state, offers: newOffers });
  }

  function filterProductsByColor(items) {
    if (!selectedColor) {
      return items;
    }

    return items.filter((item) => item.color === selectedColor.name);
  }

  function filterByOfferType(items) {
    if (someProductIsSubscription) {
      return items.filter((item) => {
        if (selectedOfferType === 'subscription') {
          return item.billingModelId !== '2';
        }

        return item.billingModelId === '2';
      });
    }

    return items;
  }

  function filterProducts(items) {
    const filteredByColor = filterProductsByColor(items);
    return filterByOfferType(filteredByColor);
  }

  function getSavings() {
    const qty = selectedProduct?.quantity;

    if (!qty) {
      return 0;
    }

    const oneTimeProduct = mergedProductsData.find(
      (item) => item.billingModelId === '2' && item.quantity === qty
    );
    const subscriptionProduct = mergedProductsData.find(
      (item) => item.billingModelId !== '2' && item.quantity === qty
    );

    return getPriceWithCurrency(
      oneTimeProduct.price * 1 + oneTimeProduct.shippingPrice * 1 - subscriptionProduct.price * 1,
      offer?.locale,
      offer?.currency
    );
  }

  // Handlers

  function handleColorSelect(color) {
    setSelectedColor(color);
    const newProduct = mergedProductsData.find(
      (item) => item.color === color.name && item.quantity === selectedProduct?.quantity
    );

    if (newProduct?.soldOut === '1') {
      setSelectedProduct(null);
    }

    setSelectedProduct(newProduct);
  }

  function handleFieldBlur(fieldName) {
    const errorMessage = validateField(fieldName, state[fieldName]);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: errorMessage,
    }));
  }

  function handleCrossSellChange(crossSell) {
    updateOffers(crossSell, { isCrossSell: true });
  }

  function handleExpressApprove(data) {
    handleCreateOrder(data);
  }

  function handleFormSubmit() {
    const validationErrors = validateForm(state);
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      handleCreateOrder(state);
    } else {
      const firstErrorField = Object.keys(validationErrors)[0];
      const fieldElement = document.querySelector(`[name="${firstErrorField}"]`);
      if (fieldElement) {
        fieldElement.focus();
      }
    }
  }

  function handleCreateOrder(data) {
    const stickyOffer = {
      billing_model_id: selectedProduct?.billingModelId,
      offer_id: selectedProduct?.offerId,
      product_id: selectedProduct?.id,
      price: selectedProduct?.price,
      productQty: selectedProduct?.quantity,
      productName: selectedProduct?.name,
      isMainProduct: true,
    };

    const currentOffers = state.offers || [];
    const newOffers = currentOffers.filter((offer) => !offer.isMainProduct);
    newOffers.push(stickyOffer);

    const offersTotal = newOffers.reduce((total, offer) => total + offer.price * 1, 0);
    const orderTotal = offersTotal + selectedProduct?.shippingPrice * 1;
    const expirationDate = `${state.expirationMonth}${state.expirationYear.slice(-2)}`;

    const orderData = {
      ...data,
      expirationDate,
      orderTotal,
      offers: newOffers,
      shippingId: selectedProduct?.shippingId,
      shippingPrice: selectedProduct?.shippingPrice,
      productId: selectedProduct?.id,
      productQty: selectedProduct?.quantity,
      productName: selectedProduct?.name,
      productPrice: selectedProduct?.price,
    };

    if (!selectedProduct) {
      setErrors({ ...errors, product: 'Please select a product' });
      return;
    } else {
      setErrors({ ...errors, product: null });
    }

    onCreateOrder(orderData);
  }

  function renderStateSelect() {
    if (state.shippingCountry === 'US' || state.shippingCountry === 'CA') {
      return (
        <div className={styles.inputGroup}>
          <label htmlFor='shippingState'>State/Province</label>
          <div className={styles.formSelect}>
            <select
              name='shippingState'
              value={state.shippingState}
              onChange={(e) => {
                setState({ ...state, shippingState: e.target.value });
                setErrors((prevErrors) => ({ ...prevErrors, shippingState: null }));
              }}
              onBlur={() => handleFieldBlur('shippingState')}
            >
              {Object.entries(states[state.shippingCountry]).map(([code, name], index) => {
                return (
                  <option key={index} value={code}>
                    {name}
                  </option>
                );
              })}
            </select>
          </div>
          {errors.shippingState && <div className={styles.errorMessage}>{errors.shippingState}</div>}
        </div>
      );
    }

    return (
      <div className={styles.inputGroup}>
        <label htmlFor='shippingState'>County/District/Region</label>
        <input
          placeholder='County/District/Region'
          type='text'
          name='shippingState'
          value={state.shippingState}
          className={errors.shippingState ? styles.errorInput : ''}
          onChange={(e) => {
            setState({ ...state, shippingState: e.target.value });
            setErrors((prevErrors) => ({ ...prevErrors, shippingState: null }));
          }}
          onBlur={() => handleFieldBlur('shippingState')}
        />

        {errors.shippingState && <div className={styles.errorMessage}>{errors.shippingState}</div>}
      </div>
    );
  }

  // Render

  return (
    <div className='template3'>
      <Banner banners={banners} discount={getMaxDiscount(offer)} />
      {/* Header */}
      <header className={styles.header}>
        <div className={styles.container}>
          <div className={styles.headerInner}>
            <div className={styles.headerLeft}>
              <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
                <path d='M256 0c36.8 0 68.8 20.7 84.9 51.1C373.8 41 411 49 437 75s34 63.3 23.9 96.1C491.3 187.2 512 219.2 512 256s-20.7 68.8-51.1 84.9C471 373.8 463 411 437 437s-63.3 34-96.1 23.9C324.8 491.3 292.8 512 256 512s-68.8-20.7-84.9-51.1C138.2 471 101 463 75 437s-34-63.3-23.9-96.1C20.7 324.8 0 292.8 0 256s20.7-68.8 51.1-84.9C41 138.2 49 101 75 75s63.3-34 96.1-23.9C187.2 20.7 219.2 0 256 0zM192 224a32 32 0 1 0 0-64 32 32 0 1 0 0 64zm160 96a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zM337 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0L175 303c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0L337 209z' />
              </svg>
              <p>
                Hurry! <b>{getSavingsPercent(selectedProduct?.oldPrice, selectedProduct?.price)}% discount</b>{' '}
                reserved for:
              </p>
            </div>
            <div className={styles.headerRight}>
              <div className={styles.headerRightTime}>
                <b>{String(timeLeft.hours).padStart(2, '0')}</b> <p>HOURS</p>
              </div>
              <b>:</b>
              <div className={styles.headerRightTime}>
                <b>{String(timeLeft.minutes).padStart(2, '0')}</b> <p>MINUTES</p>
              </div>
              <b>:</b>
              <div className={styles.headerRightTime}>
                <b>{String(timeLeft.seconds).padStart(2, '0')}</b>
                <p>SECONDS</p>
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* Hero */}
      <section className={styles.hero}>
        <div className={styles.container}>
          <div className={styles.heroInner}>
            <div className={styles.heroLeft}>
              <img src={offer.logoSrc} alt={offer.brand} />
            </div>
            <div className={styles.heroRight}>
              <img src={offer.productImageSrc} alt={offer.productName} />
            </div>
          </div>
        </div>
      </section>
      {/* Checkout */}
      <div className={styles.container}>
        <section className={styles.checkout}>
          {/* Checkout Left */}
          <article className={styles.checkoutLeft}>
            {/* Benefits and Discount */}
            <div className={styles.benefitsAndDiscount}>
              <div className={styles.benefits}>
                {offer.benefitsWithIcons.map((benefit, index) => (
                  <div key={index} className={styles.benefit}>
                    <img src={benefit.icon} alt={benefit.name} />
                    <p>{benefit.name}</p>
                  </div>
                ))}
              </div>
              <div className={styles.discount}>
                <div className={styles.discountImage}>
                  <p>{getSavingsPercent(selectedProduct?.oldPrice, selectedProduct?.price)}% OFF</p>
                </div>
                <div className={styles.discountContent}>
                  <p style={{ color: 'red' }}>
                    YOUR {getSavingsPercent(selectedProduct?.oldPrice, selectedProduct?.price)}% DISCOUNT
                    APPLIED SUCCESSFULLY
                  </p>
                  <p>
                    {offer.productName} is available at the price of:{' '}
                    <s>{getPriceWithCurrency(selectedProduct?.oldPrice, offer?.locale, offer?.currency)}</s>{' '}
                    <b>{getPriceWithCurrency(selectedProduct?.price, offer?.locale, offer?.currency)}</b> (
                    {getSavingsPercent(selectedProduct?.oldPrice, selectedProduct?.price)}% Discount)
                  </p>
                </div>
              </div>
            </div>
            {/* Step 1 */}
            <div className={styles.step1}>
              <div className={styles.stepTitle}>
                <i className='fa-solid fa-gift'></i> Select Quantity
              </div>
              {someProductIsSubscription && (
                <div className={styles.offerSelector}>
                  <button
                    className={`${styles.offerSelectorButton} ${
                      selectedOfferType === 'one-time' && styles.offerSelectorButtonActive
                    }`}
                    onClick={() => setSelectedOfferType('one-time')}
                  >
                    One-Time Purchase
                  </button>
                  <button
                    className={`${styles.offerSelectorButton} ${
                      selectedOfferType === 'subscription' && styles.offerSelectorButtonActive
                    }`}
                    onClick={() => setSelectedOfferType('subscription')}
                  >
                    <p>
                      Subscribe & <span>save extra {getSavings()}</span>
                    </p>
                    <b>+FREE SHIPPING ALWAYS</b>
                  </button>
                </div>
              )}

              {/* Products */}
              <div className={styles.products}>
                {filterProducts(mergedProductsData).map((product) => (
                  <div
                    key={product.id}
                    className={`${styles.product} ${
                      selectedProduct?.id === product.id && styles.productActive
                    } ${product.soldOut === '1' && styles.productSoldOut}`}
                    onClick={() => setSelectedProduct(product)}
                  >
                    {product.mostPopular && <div className={styles.productPopular}>Most Popular</div>}
                    {product.bestValue && <div className={styles.productBestValue}>Best Value</div>}
                    {product.soldOut === '1' && <div className={styles.productSoldOutBadge}>Sold Out</div>}
                    <div className={styles.productImage}>
                      <img src={product.image} alt={offer.productName} />
                    </div>
                    <div className={styles.productContent}>
                      <b>{product.quantity}x</b>
                      {product.subtitle && <p className={styles.productSubtitle}>{product.subtitle}</p>}
                      <p
                        dangerouslySetInnerHTML={{
                          __html: makePlural(offer.productName, product.quantity * 1),
                        }}
                      ></p>
                      <s>{getPriceWithCurrency(product.oldPrice, offer?.locale, offer?.currency)}</s>
                      <span>{getPriceWithCurrency(product.price, offer?.locale, offer?.currency)}</span>
                    </div>
                  </div>
                ))}
              </div>

              {/* Color Select */}
              {selectedColor && selectedColor.name && (
                <ColorSelect
                  colors={mapColorsFromProducts(mergedProductsData)}
                  onChange={handleColorSelect}
                  selectedColor={selectedColor}
                  defaultProduct={mergedProductsData.find((item) => item.id === defaultVariantId)}
                />
              )}

              {crossSells.length > 0 && (
                <CrossSells
                  offer={offer}
                  offers={state.offers}
                  crossSells={crossSells}
                  onChange={handleCrossSellChange}
                />
              )}
            </div>
          </article>
          {/* Checkout Right */}
          <article className={styles.checkoutRight}>
            {/* Express Checkout */}
            {clientToken && showPayPal && (
              <div className={styles.expressCheckout}>
                <PayPalScriptProvider
                  options={{
                    vault: true,
                    clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
                    components: 'buttons',
                    dataClientToken: clientToken,
                  }}
                >
                  <PayPalButtons
                    offer={offer}
                    orderData={state}
                    checkoutTotal={state.orderTotal}
                    onApprove={handleExpressApprove}
                  />
                </PayPalScriptProvider>
              </div>
            )}
            {/* Order Form */}
            <div className={styles.orderForm}>
              <div className={styles.stepTitle}>
                <i className='fa-solid fa-user'></i> Customer Information
              </div>
              {/* address inputs */}
              <div className={styles.inputGroup}>
                <label htmlFor='email'>Email Address</label>
                <input
                  placeholder='Email Address'
                  type='email'
                  name='email'
                  value={state.email}
                  className={errors.email ? styles.errorInput : ''}
                  onChange={(e) => {
                    setState({ ...state, email: e.target.value });
                    setErrors((prevErrors) => ({ ...prevErrors, email: null }));
                  }}
                  onBlur={() => handleFieldBlur('email')}
                />
                {errors.email && <div className={styles.errorMessage}>{errors.email}</div>}
              </div>

              <div className={styles.inputGroup}>
                <label htmlFor='firstName'>First Name</label>
                <input
                  placeholder='First Name'
                  type='text'
                  name='firstName'
                  value={state.firstName}
                  className={errors.firstName ? styles.errorInput : ''}
                  onChange={(e) => {
                    setState({ ...state, firstName: e.target.value });
                    setErrors((prevErrors) => ({ ...prevErrors, firstName: null }));
                  }}
                  onBlur={() => handleFieldBlur('firstName')}
                />
                {errors.firstName && <div className={styles.errorMessage}>{errors.firstName}</div>}
              </div>
              <div className={styles.inputGroup}>
                <label htmlFor='lastName'>Last Name</label>
                <input
                  placeholder='Last Name'
                  type='text'
                  name='lastName'
                  value={state.lastName}
                  className={errors.lastName ? styles.errorInput : ''}
                  onChange={(e) => {
                    setState({ ...state, lastName: e.target.value });
                    setErrors((prevErrors) => ({ ...prevErrors, lastName: null }));
                  }}
                  onBlur={() => handleFieldBlur('lastName')}
                />
                {errors.lastName && <div className={styles.errorMessage}>{errors.lastName}</div>}
              </div>
              <div className={styles.inputGroup}>
                <label htmlFor='phone'>Phone Number</label>
                <input
                  placeholder='Phone Number'
                  type='tel'
                  name='phone'
                  value={state.phone}
                  className={errors.phone ? styles.errorInput : ''}
                  onChange={(e) => {
                    setState({ ...state, phone: e.target.value });
                    setErrors((prevErrors) => ({ ...prevErrors, phone: null }));
                  }}
                  onBlur={() => handleFieldBlur('phone')}
                />
                {errors.phone && <div className={styles.errorMessage}>{errors.phone}</div>}
              </div>

              <div className={styles.inputGroup}>
                <label htmlFor='shippingAddress1'>Shipping Address</label>
                <input
                  placeholder='Shipping Address'
                  type='text'
                  name='shippingAddress1'
                  value={state.shippingAddress1}
                  className={errors.shippingAddress1 ? styles.errorInput : ''}
                  onChange={(e) => {
                    setState({ ...state, shippingAddress1: e.target.value });
                    setErrors((prevErrors) => ({ ...prevErrors, shippingAddress1: null }));
                  }}
                  onBlur={() => handleFieldBlur('shippingAddress1')}
                />
                {errors.shippingAddress1 && (
                  <div className={styles.errorMessage}>{errors.shippingAddress1}</div>
                )}
              </div>

              <div className={styles.inputGroup}>
                <label htmlFor='shippingCity'>City</label>
                <input
                  placeholder='City'
                  type='text'
                  name='shippingCity'
                  value={state.shippingCity}
                  className={errors.shippingCity ? styles.errorInput : ''}
                  onChange={(e) => {
                    setState({ ...state, shippingCity: e.target.value });
                    setErrors((prevErrors) => ({ ...prevErrors, shippingCity: null }));
                  }}
                  onBlur={() => handleFieldBlur('shippingCity')}
                />
                {errors.shippingCity && <div className={styles.errorMessage}>{errors.shippingCity}</div>}
              </div>

              <div className={styles.inputGroup}>
                <label htmlFor='shippingCountry'>Country</label>
                <div className={styles.formSelect}>
                  <select
                    name='shippingCountry'
                    value={state.shippingCountry}
                    onChange={(e) => setState({ ...state, shippingCountry: e.target.value })}
                  >
                    {offer.shippingCountries.map((country) => (
                      <option key={country.id} value={country.id}>
                        {country.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className={styles.formRow}>
                {renderStateSelect()}
                <div className={styles.inputGroup}>
                  <label htmlFor='shippingZip'>Zip/Postal Code</label>
                  <input
                    placeholder='Zip/Postal Code'
                    type='text'
                    name='shippingZip'
                    value={state.shippingZip}
                    className={errors.shippingZip ? styles.errorInput : ''}
                    onChange={(e) => {
                      setState({ ...state, shippingZip: e.target.value });
                      setErrors((prevErrors) => ({ ...prevErrors, shippingZip: null }));
                    }}
                    onBlur={() => handleFieldBlur('shippingZip')}
                  />
                  {errors.shippingZip && <div className={styles.errorMessage}>{errors.shippingZip}</div>}
                </div>
              </div>

              <div className={styles.formShipping}>
                <b>Shipping:</b>
                <span>
                  {selectedProduct?.shippingPrice > 0
                    ? getPriceWithCurrency(selectedProduct?.shippingPrice, offer?.locale, offer?.currency)
                    : 'FREE'}
                </span>
              </div>

              {/* payment inputs */}

              <div className={styles.stepTitle}>
                <i className='fa-solid fa-credit-card'></i> Payment Options
              </div>

              <div className={styles.creditCardBlock}>
                <div className={styles.creditCardBlockHeader}>
                  <b>Credit Card</b>
                  <div className={styles.creditCardBlockHeaderCards}>
                    <img src={visa} alt='visa' />
                    <img src={master} alt='mastercard' />
                    <img src={amex} alt='amex' />
                  </div>
                </div>
                <div className={styles.inputGroup}>
                  <label htmlFor='creditCardNumber'>Card Number</label>
                  <NumberFormat
                    name='creditCardNumber'
                    format='#### #### #### ####'
                    placeholder='Credit Card Number'
                    value={state.creditCardNumber}
                    className={errors.creditCardNumber ? styles.errorInput : ''}
                    onValueChange={(values) => {
                      const { value } = values;
                      setState({ ...state, creditCardNumber: value });
                      setErrors((prevErrors) => ({ ...prevErrors, creditCardNumber: null }));
                    }}
                    onBlur={() => handleFieldBlur('creditCardNumber')}
                  />
                  {errors.creditCardNumber && (
                    <div className={styles.errorMessage}>{errors.creditCardNumber}</div>
                  )}
                </div>

                {/* Expiration Date Fields */}

                <div className={styles.formRow}>
                  {/* Expiration Month Select */}
                  <div className={styles.inputGroup}>
                    <label htmlFor='expirationMonth'>Expiry Month</label>
                    <div className={styles.formSelect}>
                      <select
                        name='expirationMonth'
                        value={state.expirationMonth}
                        onChange={(e) => {
                          setState({ ...state, expirationMonth: e.target.value });
                          setErrors((prevErrors) => ({ ...prevErrors, expirationMonth: null }));
                        }}
                        onBlur={() => handleFieldBlur('expirationMonth')}
                        className={errors.expirationMonth ? styles.errorInput : ''}
                      >
                        <option value=''>Month</option>
                        <option value='01'>01 - January</option>
                        <option value='02'>02 - February</option>
                        <option value='03'>03 - March</option>
                        <option value='04'>04 - April</option>
                        <option value='05'>05 - May</option>
                        <option value='06'>06 - June</option>
                        <option value='07'>07 - July</option>
                        <option value='08'>08 - August</option>
                        <option value='09'>09 - September</option>
                        <option value='10'>10 - October</option>
                        <option value='11'>11 - November</option>
                        <option value='12'>12 - December</option>
                      </select>
                    </div>
                    {errors.expirationMonth && (
                      <div className={styles.errorMessage}>{errors.expirationMonth}</div>
                    )}
                  </div>
                  {/* Expiration Year Select */}
                  <div className={styles.inputGroup}>
                    <label htmlFor='expirationMonth'>Expiry Year</label>
                    <div className={styles.formSelect}>
                      <select
                        name='expirationYear'
                        value={state.expirationYear}
                        onChange={(e) => {
                          setState({ ...state, expirationYear: e.target.value });
                          setErrors((prevErrors) => ({ ...prevErrors, expirationYear: null }));
                        }}
                        onBlur={() => handleFieldBlur('expirationYear')}
                        className={errors.expirationYear ? styles.errorInput : ''}
                      >
                        <option value=''>Year</option>
                        {Array.from({ length: 20 }, (_, i) => {
                          const year = new Date().getFullYear() + i;
                          return (
                            <option key={year} value={year}>
                              {year}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    {errors.expirationYear && (
                      <div className={styles.errorMessage}>{errors.expirationYear}</div>
                    )}
                  </div>
                </div>

                {/* CVV Input */}
                <div className={styles.inputGroup}>
                  <label htmlFor='CVV'>CVV</label>
                  <NumberFormat
                    name='CVV'
                    format='####'
                    placeholder='CVV'
                    value={state.CVV}
                    className={errors.CVV ? styles.errorInput : ''}
                    onValueChange={(values) => {
                      const { value } = values;
                      setState({ ...state, CVV: value });
                      setErrors((prevErrors) => ({ ...prevErrors, CVV: null }));
                    }}
                    onBlur={() => handleFieldBlur('CVV')}
                  />
                  {errors.CVV && <div className={styles.errorMessage}>{errors.CVV}</div>}
                </div>

                {/* <div className={styles.inputGroup}>
                  <div className={styles.termsCheckbox}>
                    <input
                      type='checkbox'
                      id='terms'
                      name='terms'
                      checked={state.isTermsAgreed}
                      className={errors.isTermsAgreed ? styles.errorInput : ''}
                      onChange={(e) => {
                        setState({ ...state, isTermsAgreed: e.target.checked });
                        setErrors((prevErrors) => ({ ...prevErrors, isTermsAgreed: null }));
                      }}
                      onBlur={() => handleFieldBlur('isTermsAgreed')}
                    />
                    <label htmlFor='terms'>
                      I agree to the{' '}
                      <b
                        onClick={(e) => {
                          e.preventDefault();
                          onTermsClick();
                        }}
                      >
                        terms and conditions
                      </b>
                    </label>
                  </div>
                  {errors.isTermsAgreed && <div className={styles.errorMessage}>{errors.isTermsAgreed}</div>}
                </div> */}
                <div className={styles.inputGroup}>
                  <div className={styles.termsCheckbox}>
                    <input
                      type='checkbox'
                      id='agreeSms'
                      name='agreeSms'
                      checked={state.agreeSms}
                      className={`${errors.agreeSms ? styles.errorInput : ''} ${styles.bigCheckbox}`}
                      onChange={(e) => {
                        setState({ ...state, agreeSms: e.target.checked });
                      }}
                    />
                    <label htmlFor='agreeSms'>Text Me Shipping Updates and Exclusive Discounts</label>
                  </div>
                  {state.agreeSms && (
                    <div className={styles.smsText}>
                      By clicking this checkbox, you agree to receive marketing text messages from our company
                      at the number provided. Consent is not a condition of purchase. Message and data rates
                      may apply. Message frequency varies. Reply HELP for help or STOP to cancel. View our
                      Privacy Policy and Terms of Service.
                    </div>
                  )}
                </div>
                {/* button */}

                <p className={styles.termsText}>
                  By placing your order, you agree to{' '}
                  <span onClick={() => onTermsClick()}>terms and conditions</span> of service.
                </p>
                <button
                  onClick={() => handleFormSubmit()}
                  className={styles.submitButton}
                  type='button'
                  disabled={isLoading}
                >
                  Complete Purchase {''}
                  {isLoading && <i className='fas fa-spinner fa-spin'></i>}
                </button>
                {declineError && (
                  <div className={styles.declineError}>
                    <p>{declineError}</p>
                  </div>
                )}
                {errors.product && <div className={styles.declineError}>{errors.product}</div>}
              </div>

              <div className={styles.checkoutRightFooter}>
                <img src={safe} alt='safe icons' />
                <p>
                  <b>
                    <span>
                      <i className='fa-solid fa-lock'></i> SSL
                    </span>{' '}
                    SECURE PAYMENT
                  </b>
                  <span>Your Personal Details Are Securely Encrypted With 256-Bit-SSL</span>
                </p>

                <MoneyBackGuarantee />

                <div className={styles.checkoutRightFooterBenefits}>
                  {offer.benefits.map((benefit, index) => (
                    <div key={index} className={styles.checkoutRightFooterBenefit}>
                      <i className='fa-solid fa-check'></i>
                      <p dangerouslySetInnerHTML={{ __html: benefit }}></p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {/* Checkout Right Footer */}
          </article>
        </section>
      </div>
    </div>
  );
}
